.cardscan__card {
  padding: 12px 24px 24px;
  position: relative;
}

a.page-link {
  color: #12263f !important;
}

td {
  color: #12263f;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
}

.checkbox__card {
  margin-bottom: 0px !important;
}

.card__switch {
  display: flex !important;
}

.card__switch > :not(:last-child) {
  margin-right: 8px;
}

.card__switch > :last-child {
  margin-right: 0;
}

.header-title {
  margin-top: 0;
  font-size: 1.625rem;
  font-weight: 700;
}

.header-title {
  font-weight: 600;
}

.card__list-container {
  flex-wrap: wrap;
  align-items: stretch !important;
}

.card__image {
  max-width: 200px;
  max-height: 120px;
}

.card__checkbox {
  position: absolute;
  top: 20px;
  left: 12px;
}

.card__icon-button {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #d2ddec;
  cursor: pointer;
}

.card__header-status {
  color: #fff;
  background-color: #63aeff;
  border-radius: 12px;
  font-size: 13px;
  line-height: 20px;
  margin: auto;
  /* margin-bottom: 20px; */
  width: 102px;
  text-transform: capitalize;
}

.card__header-status-processing {
  background-color: #6e84a3;
}

.card__header-status-error {
  background-color: #f5d83e;
  color: #000;
}

.card__header-container {
  align-items: center;
}

.card__header-name {
  color: #000;
  font-weight: 700;
  font-size: 17px;
  line-height: 1.3333;
  text-transform: capitalize;
}

.card__section-title {
  color: #1f2d3d;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 0px;
}

.card__section-text {
  color: #6e84a3;
  font-size: 13px;
  margin-bottom: 0px;
}

.card__tab-badge {
  color: #6e84a3;
  font-size: 13px;
  font-weight: 600;
  border-radius: 50%;
  padding: 6px;
  background-color: rgba(43, 52, 64, 0.1);
}

.divider {
  color: #edf2f9;
  margin-top: 12px;
  margin-bottom: 12px;
}

.dropdown-toggle.nav-link {
  color: #6e84a3;
}

.card__pagination {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  width: 100%;
}

.card__pagination > li a {
  border-radius: 7px;
  padding: 0.1rem 1rem;
  color: #95aac9;
  cursor: pointer;
}

.card__pagination > li.previous a,
li.next a,
li.break a {
  border-color: transparent;
}

.card__pagination > li.active a {
  color: #12263f;
  border-top: 1px solid #2c7be5;
  min-width: 32px;
}
