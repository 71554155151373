.card-scan--main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding: 24px;
}

.card-scan--main > :last-of-type {
  margin-top: 80px;
}


@media (min-width: 1121px) {

  .card-scan--main {
    flex-direction: row;
  }

  .card-scan--main > * {
    margin-right: 170px;
  }

  .card-scan--main > :last-of-type {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.card-scan--companies {
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 100px;
}

.card-scan--companies > * {
  margin-right: 12px;
}

section {
  margin: 100px auto 0px;
  width:  min(100%, 550px);
}

.card-scan--subtitle {
  text-align: center;
  font-size: 31px;
  font-weight: 600;
}

strong {
  font-size: inherit;
  font-weight: 700;
}

.card-scan--text-primary {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  color: #6b7c93;
  opacity: 0.8;
  padding: 8px;
}

.card-scan--spinner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.card-scan--spinner-wrapper > * {
  margin-bottom: 60px;
}

.card-scan--spinner-wrapper > p {
  color: #506690;
  font-weight: 400;
}
