.card-scan--card {
  width: min(95%, 400px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 24px 64px 0px #161b2d1a;
  padding: 30px 70px;
  background: #FFF;
}

.card-scan--card > * {
  margin-bottom: 22px;
  text-align: center;
}

.card-scan--card > h4 {
  font-size: 19px;
  font-weight: 600;
  color: #161C2D;
}

.card-scan--card > p {
    color: #506690;
    font-weight: 400;
}

.card-scan--card.left {
  min-height: 400px;
  border-top: 4px solid #7c69ef;
}

.card-scan--card.right {
  min-height: 400px;
  border-top: 4px solid #335eea;
  position: relative;
}

@media (min-width: 1121px) {
  .card-scan--card.right {
    align-self: stretch;
  }
}
