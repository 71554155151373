.card-scan--banner {
    background-image: url(../../assets/images/banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    min-height: 250px;
    width: 100%;
    color: white;
}

.card-scan--banner > * {
    text-align: center;
    margin: 0;
}

.card-scan--banner > h1 {
    font-size: 61px;
    font-weight: 600;
}

.card-scan--banner > p {
    font-weight: 400;
    font-size: 20px;
    max-width: min(100%, 500px);
    opacity: 0.75;
}
