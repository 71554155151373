.result-button {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #2856ed;
  height: 50px;
  border-radius: 6px;
  min-width: 182px;
  color: #FFF;
}

.results-page h2 {
  font-weight: 600;
  font-size: 31px;
  color: #161B2D;
  margin-top: 0px;
}

.results-page h4 {
  font-weight: 400;
  font-size: 19px;
  color: #6B7C93;
  margin-top: 0px;
}

