.card-scan--button {
    min-width: 145px;
    min-height: 50px;
    border-radius: 6px;
    outline: none;
    border: none;
    cursor: pointer;
}

.primary {
    background-color: #335EEA;
    color: white
}

.secondary {
    background-color: #E9EEFD;
    color: #335EEA;
}