@import "normalize.css/opinionated.css";
@import '@aws-amplify/ui-react/styles.css';


* {
  box-sizing: border-box;
  /* font-size: 17px;
  line-height: 1.5;
  font-family: "HK Grotesk Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
}

body {
  background-color: #F9FBFD;
}

@font-face {
  font-family: "Oxanium";
  src: url(./assets/fonts/Oxanium/Oxanium-Light.ttf);
}

@font-face {
  font-family: "HK Grotesk Pro";
  src: url(./assets/fonts/HK/HKGroteskPro-Regular.otf);
}

hr {
  margin-top: 100px;
  color: #D9E2EF;
}

.amplify-button {
  border-radius: 6px !important;
}

.auth-image {
  object-position: 70%;
  overflow: clip !important;
}

[data-amplify-authenticator] {

  font-family: "Cerebri Sans", sans-serif;
  font-size: 15px;
 
  --amplify-primary-color: #335eea;
  --amplify-primary-tint: #476ce7;
  --amplify-primary-shade: #294ec7;
  --amplify-fonts-default-variable: "Open Sans";
  --amplify-fonts-default-static: "Open Sans";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: #f2f2f3;
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);
  --amplify-components-button-primary-background-color:  #335eea;
  --amplify-components-button-primary-hover-background-color:  #294ec7;
  --amplify-components-button-primary-border-radius: 6px;
  --amplify-components-fieldcontrol-border-radius: 6px;

  /* --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0; */
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}


.amplify-tabs {
  display: none;
}

 .amplify-field-group__field-wrapper .amplify-input.amplify-field-group__control{
  border-end-end-radius: 6px ;
  border-start-end-radius:  6px;
}

.amplify-field-group--horizontal{
  gap: 10px;
}

.amplify-field-group__outer-end .amplify-field-group__control:not(:focus) {
  border-inline-start-color: revert;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}